<template>
    <div class="agendaroom-comment">
        <CompTable isSelector ref="comp_table" :title="'社区动态审核'" :columns="columns" :tableApi="tableApi" :dataList="dataList">
            <template #operate>
                <RadioGroup v-model="activity" type="button" button-style="solid">
                    <Radio :label="0">屏蔽</Radio>
                    <Radio :label="1">显示</Radio>
                </RadioGroup>
                <LiefengCascaderCity
                    v-if="showCascader && buttonRoot == '1003'"
                    @changeCasader="changeCasader"
                    :resetNum="resetNum"
                    :orgCode="4401"
                    :width="200"
                    :isClear="true"
                ></LiefengCascaderCity>
            </template>
        </CompTable>

        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import CompTable from "../jointly/components/comp-table"
import Preview from "../comment/components/Preview.vue"

import Route from "../communitymobilization/entity/Route"
import Request from "../jointly/utils/request"

import LiefengCascaderCity from "@/components/LiefengCascaderCity"

export default {
    components: { CompTable, Preview,LiefengCascaderCity },

    data() {
        return {
            tableApi: null,
            columns: [
                {
                    title: "内容",
                    render: (h, params) => {
                        const files = []

                        const images = params.row.photo?.split(",")

                        images?.forEach(item => {
                            if (!item) return
                            files.push(
                                h("div", {
                                    style: {
                                        width: "50px",
                                        height: "50px",
                                        margin: "3px",
                                        background: `url(${item})`,
                                        "background-size": "cover",
                                        "background-position": "center",
                                        "border-radius": "5px",
                                        cursor: "pointer",
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.preview.display(item, images)
                                        },
                                    },
                                })
                            )
                        })

                        return h(
                            "div",
                            {
                                style: {
                                    margin: "10px 0",
                                },
                            },
                            [
                                h(
                                    "p",
                                    {
                                        style: {
                                            "text-align": "center",
                                        },
                                    },
                                    params.row.content || "-"
                                ),
                                ...(files.length > 0
                                    ? [
                                          h(
                                              "div",
                                              {
                                                  style: {
                                                      display: "flex",
                                                      "justify-content": "center",
                                                      "flex-wrap": "wrap",
                                                      "margin-top": "5px",
                                                  },
                                              },
                                              files
                                          ),
                                      ]
                                    : []),
                            ]
                        )
                    },
                },
                {
                    title: "日期",
                    render: (h, params) => {
                        const date = new Date(params.row.gmtCreate)
                        return this.$core.formatDate(date, (date.getFullYear() !== new Date().getFullYear() ? "yyyy年" : "") + "MM月dd日")
                    },
                },
                {
                    title: "来源",
                    key: "title",
                },
                {
                    title: "状态",
                    render: (h, params) => {
                        return h(
                            "RadioGroup",
                            {
                                props: {
                                    value: params.row.status == 2 ? "1" : "0",
                                },
                                on: {
                                    "on-change": () => {
                                        Request.post("/gateway/syinfopublish/api/app/infoComments/updateDisplayStatus", {
                                            id: params.row.id,
                                        }).then(() => {
                                            this.$Message.success("修改成功")
                                            // 刷新列表
                                            this.$refs.comp_table.refresh()
                                        })
                                    },
                                },
                            },
                            [
                                h(
                                    "Radio",
                                    {
                                        attrs: {
                                            label: "0",
                                        },
                                    },
                                    "屏蔽"
                                ),
                                h(
                                    "Radio",
                                    {
                                        attrs: {
                                            label: "1",
                                        },
                                    },
                                    "公开"
                                ),
                            ]
                        )
                        // const date = new Date(params.row.gmtCreate)
                        // return this.$core.formatDate(date, (date.getFullYear() !== new Date().getFullYear() ? "yyyy年" : "") + "MM月dd日")
                    },
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDelete(params.row.id)
                                        },
                                    },
                                },
                                "删除"
                            ),

                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.title = "畅联通"
                                            this.showModal = true
                                            this.onOpenMessageDetail(params.row)
                                        },
                                    },
                                },
                                "发消息"
                            ),
                        ])
                    },
                },
            ],
            // 评议
            discuss: false,
            activity: 0,
            dataList: [],
            showCascader: true,
            buttonRoot:''
        }
    },

    watch: {
        activity(v) {
            this.setTableUrl()
        },
    },

    created() {
        let userButtonRoot = parent.vue.userButtonRoot
        this.buttonRoot = userButtonRoot.includes("1001") ? "1001" : userButtonRoot.includes("1002") ? "1002" : userButtonRoot.includes("1003") ? "1003" : ""
        this.discuss = this.$core.getUrlParam("type") === "discuss"
        this.setTableUrl()
    },

    methods: {
        // 改变选择社区组件
        changeCasader(val) {
            this.cascaderList = val
            console.log(this.cascaderList);
            
            if(this.cascaderList.length != 3){
                return
            }else if(this.cascaderList.length == 3){
                this.resetNum++
                this.getTableData(this.cascaderList[this.cascaderList.length - 1])
            }  
        },
        getTableData(orgCode) {
            try {
                Request.get(this.setTableUrl(orgCode)).then(res => {
                    this.dataList = res
                })
            } catch (err) {
                console.log("获取报错", err)
            }
        },
        onOpenMessageDetail(item) {
            try {
                const loading = this.$Message.loading({
                    content: "连接中...",
                    duration: 0,
                })

                this.$get("/gateway/chat/app/chat/onlyUser/flushUser", {
                    onlyUserId: item.custGlobalId,
                })
                    .then(res => {
                        if (res.code !== "200" || !res.data) {
                            return this.$Message.error(res.desc || res.msg || "建立连接失败，请稍后再试！")
                        }
                        this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/person/chat?groupCode=${res.data}&groupName=${item.userName}&businessType=1&businessCode=2&custGlobalId=${
                            item.custGlobalId
                        }&token=${window.sessionStorage.getItem("accessToken")}`
                    })
                    .finally(() => {
                        loading()
                    })
            } catch (err) {
                console.log("err", err)
            }
        },
        onDelete(id) {
            this.$Modal.confirm({
                title: "提示",
                content: "您正在删除数据，是否继续？",
                onOk: () => {
                    this.$post("/gateway/syinfopublish/api/pc/infoComments/deleteById", {
                        id,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            // 刷新表格数据
                            this.getTableData()
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        setTableUrl(orgCode) {
            var url = `/gateway/syinfopublish/api/app/infoComments/listComments?infoType=ORGFORUM&orgCode=${orgCode && orgCode != '' ? orgCode : parent.vue.loginInfo.userinfo.communityCode && parent.vue.loginInfo.userinfo.communityCode != '' ? parent.vue.loginInfo.userinfo.communityCode : ''}`

            url += `&infoId=${orgCode && orgCode != '' ? orgCode : parent.vue.loginInfo.userinfo.communityCode && parent.vue.loginInfo.userinfo.communityCode != '' ? parent.vue.loginInfo.userinfo.communityCode : ''}&status=${this.activity === 0 ? 1 : 2}&parentId=0`

            this.tableApi = url
            return url
        },
    },
}
</script>
<style lang="less">
.agendaroom-comment {
    .menu-bar-box {
        height: 45px;
        margin: 10px 10px 20px 10px;

        .menu-box {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #f3f3f3;
            background: #fff;
            border-radius: 5px;
            display: flex;

            .slider {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 50%;
                transition: all 0.3s ease;

                &::after {
                    content: "";
                    background: #2faaf7;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    bottom: 5px;
                    right: 5px;
                    border-radius: 3px;
                }
            }

            > .item-box {
                position: relative;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                padding: 0 20px;
            }

            > .activity {
                color: #fff;
            }
        }
    }
}
</style>
